.myInput > input {
  border-radius: 0;
  padding: .6rem .5rem;
  box-shadow: 2px 2px 7px 0.5px rgba(0, 0, 0, 0.25);
}
.myTextarea > textarea {
  border-radius: 0;
  border: 0;
  box-shadow: 2px 2px 7px 0.5px rgba(0, 0, 0, 0.25);
}
.submit {
  width: 40%;
  padding: .6rem .5rem;
  border-radius: 0 !important;
  box-shadow: 3px 3px 5px rgba(1, 1, 1, 0.416);
}
@media (max-width: 768px) {
  .submit {
    width: 100%;
  }
}
