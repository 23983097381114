.articleCard {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  background-color: #353535e6;
  /* box-shadow: 5px 3px 10px 5px rgba(255, 255, 255, 0.264); */
  border-radius: 10px;
  overflow: hidden;
}
.articleTitle {
  height: 6vh;
  background-color: #212529;
  color: white;
  font-size: 1.6rem;
  padding-left: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.articleContent {
  order: 2;
  height: 100%;
  width: 100%;
  overflow: hidden;
  height: auto;
}

.articleDetails {
  color: whitesmoke;
  order: 1;
  width: 100%;
  padding: 0.5rem 0;
  font-size: 1rem;
  padding: 32px;
}
.articleDetails dl {
  width: 100%;
}
.articleDetails dt {
  background-color: rgba(0, 0, 0, 0.383);
  padding: 4px 8px 1px 8px;
  border-radius: 4px;
}
.articleDetails dd {
  padding: 4px 8px 0 8px;
}

/* Responsive for Dojos page */
@media (max-width: 890px) {
  .articleCard {
    width: 100%;
    font-size: 1rem;
  }
  .articleTitle {
    font-size: 1.3rem;
    height: 6vh;
    width: 100%;
    text-align: center;
  }
} /*
  .articleContent {
    display: flex;
    padding: 2px;
    align-content: stretch;
    width: 100%;
    flex-direction: row;
    gap: 1%;
    font-size: 1.1rem !important;
  }
 
  .articleContent {
    width: 100%;
  }

  .articleDetails {
    width: 100%;
    height: 69%;
    padding: 0 auto;
  }
  .articleDetails > ul {
    list-style: none;

    padding: 0;
  }
}
@media (max-width: 440px) {
  .articleTitle {
    font-size: 1.4rem;
    height: 9vh;
    width: 100%;
    font-weight: 800;
  }
  .articleDetails {
    font-size: 1.4rem !important;
  }
}
@media (max-width: 346px) {
  .articleTitle {
    font-size: 1rem;
    height: 6vh;
    width: 100%;
  }
}
@media (max-width: 280px) {
  .articleTitle {
    font-size: 0.9rem !important;
    height: 8vh;
  }
  .articleDetails {
    font-size: 1rem !important;
  }
} */

/* hr {
    height: 0.2px;
    background-color: rgba(0, 0, 0, 0.171);
  } */
