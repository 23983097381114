@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;500;900&family=Nunito:wght@300;600&family=Source+Sans+Pro:wght@300;600;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,200;1,200&family=Noto+Sans+JP:wght@300;500;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;800;900&display=swap");
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;

  font-family: -apple-system, BlinkMacSystemFont, "Monserrat", "Source Sans Pro",
    "IBM Plex Sans", "Noto Sans JP", "Verdana", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.swiper-pagination {
  bottom: 0 !important;
}
.swiper-slide {
  padding: 1rem 0 1rem 0 !important;
}
@media (max-width: 890px) {
  .swiper-button-next {
    display: none !important;
  }
  .swiper-button-prev {
    display: none !important;
  }
  .swiper-pagination-bullet {
    height: 12px !important;
    width: 12px !important;
  }
}
