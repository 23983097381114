.contactViewSection {
  background-color: whitesmoke;
}
.aboutAiki {
  color: whitesmoke;
  padding: 2rem 0 0 0;
}
.dojoSection {
  color: whitesmoke;
}
@media (max-width: 980px) {
  .aboutAiki p {
    text-align: center;
  }
}
